import React, { useRef, useState } from 'react';

import './app.scss';

import Header from './Header';

function App() {
  return (
    <div className="app">
      <Header />
      <div className="content">
        <h1>
          No time to update the site, <br /> get in touch for credentials.
        </h1>
      </div>
    </div>
  );
}

export default App;
