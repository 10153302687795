import React from 'react';

const Mail = () => (
  // eslint-disable-next-line
  <a href="mailto:li.rui@mac.com" target="_blank">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      strokeWidth="10"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        // fill="none"
        id="envelope"
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
        // stroke="#fff"
        // strokeWidth="1."
      />
    </svg>
  </a>
);

export default Mail;
