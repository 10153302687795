import React from 'react';

import './header.scss';
import Instagram from './Instagram';
import Mail from './Mail';

const Header = () => {
  return (
    <header>
      <p className="logo">LI RUI CREATIVE</p>
      <Mail />
      <Instagram />
    </header>
  );
};

export default Header;
